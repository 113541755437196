
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93HgktPVa7Z9Meta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as airport_45hotels_45with_45parkingaxSx4KGa9rMeta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-hotels-with-parking.vue?macro=true";
import { default as airport_45parkingGs9AmTRpVrMeta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-parking.vue?macro=true";
import { default as port_45hotels_45with_45parkingqjci9tjIvMMeta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-hotels-with-parking.vue?macro=true";
import { default as port_45parkingbZCjbdxEApMeta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-parking.vue?macro=true";
import { default as healthvpdWBzirpzMeta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/health.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93uLfwxJTGFPMeta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hotel-package/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93dTohvkNkjXMeta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hro/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93nP6nBRaEydMeta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/parking/[code]-[name]-[IATA].vue?macro=true";
import { default as component_45stubUiXEQLzzR8Meta } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubUiXEQLzzR8 } from "/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2_m_il3c7dp3sxflnrobnn6izyj44m/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "IATA-airport-hotels-with-parking___en",
    path: "/:IATA()/airport-hotels-with-parking",
    component: () => import("/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-hotels-with-parking.vue")
  },
  {
    name: "IATA-airport-parking___en",
    path: "/:IATA()/airport-parking",
    component: () => import("/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-parking.vue")
  },
  {
    name: "IATA-port-hotels-with-parking___en",
    path: "/:IATA()/port-hotels-with-parking",
    component: () => import("/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-hotels-with-parking.vue")
  },
  {
    name: "IATA-port-parking___en",
    path: "/:IATA()/port-parking",
    component: () => import("/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-parking.vue")
  },
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/health.vue")
  },
  {
    name: "hotel-package-code-name-IATA___en",
    path: "/hotel-package/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hotel-package/[code]-[name]-[IATA].vue")
  },
  {
    name: "hro-code-name-IATA___en",
    path: "/hro/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hro/[code]-[name]-[IATA].vue")
  },
  {
    name: "parking-code-name-IATA___en",
    path: "/parking/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src2475953998/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/parking/[code]-[name]-[IATA].vue")
  },
  {
    name: component_45stubUiXEQLzzR8Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubUiXEQLzzR8
  }
]