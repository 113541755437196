<template>
    <div class="airport-card">
        <NuxtLink
            :to="`/${card?.code?.toLowerCase()}/airport-hotels-with-parking`"
            external
            class="airport-card__image"
        >
            <NuxtImg
                v-if="card.images?.[0]?.filename"
                :src="card.images[0].filename"
                :title="card.images[0].title"
                :alt="card.images[0].alt"
                provider="storyblok"
                format="webp"
                loading="lazy"
                densities="x1"
            />
        </NuxtLink>
        <div class="airport-card__title">{{ card?.city }}</div>
        <NuxtLink :to="`/${card?.code?.toLowerCase()}/airport-hotels-with-parking`" external class="airport-card__link">
            <span>Find my deal</span>
            <NuxtIcon name="ri:arrow-right-s-line" class="block" />
        </NuxtLink>
    </div>
</template>

<script setup lang="ts">
import type { IAirportEntity } from '~ui/types';

defineProps<{ card: IAirportEntity }>();
</script>

<style scoped lang="postcss">
.airport-card {
    &__image {
        @apply block overflow-hidden mb-4;
        &:hover {
            img {
                transform: scale(1.04);
            }
        }
        img {
            @apply block h-[300px] w-full object-cover transition-transform origin-center;
        }
    }
    &__title {
        @apply text-base lg:text-[20px] font-bold;
    }
    &__link {
        @apply text-[#0094ff] text-base inline-flex items-center;
        &:hover {
            span {
                @apply underline;
            }
        }
        .iconify {
            @apply h-4 w-4 ml-3 text-sm;
        }
    }
}
</style>
