<template>
    <UiCoreLayout v-editable="blok" class="sb-core-layout" data-test="sb-core-layout" :blok="blok" />
</template>

<script setup lang="ts">
import type { ICoreLayout } from '~ui/types/components/CoreLayout';

defineProps<{ blok: ICoreLayout }>();
</script>

<style scoped lang="postcss">
.ui-core-layout {
    :deep(.core-layout-mobile-carousel) {
        @apply px-6 sm:px-10;
    }
    :deep(.carousel) {
        .carousel__next,
        .carousel__prev {
            @apply h-10 w-10 text-lg rounded-full bg-white transition-all -translate-y-1/2 text-[#0094FF] mx-0;
            @apply xl:w-12 xl:h-12 xl:text-2xl;

            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);

            .iconify {
                @apply text-[#8C8C8C];
            }
        }
        .carousel__next {
            @apply -right-4 xl:left-full;
        }
        .carousel__prev {
            @apply -left-4 xl:right-full xl:-translate-x-full;
        }
        .carousel__next--disabled,
        .carousel__prev--disabled {
            @apply opacity-0 pointer-events-none;
        }
        .carousel__pagination {
            @apply flex sm:hidden mt-6;
        }
        .carousel__pagination-button {
            &::after {
                @apply w-2 h-2 rounded-full bg-gray-400 opacity-25;
            }
        }
        .carousel__pagination-button--active,
        .carousel__pagination-button:hover {
            &::after {
                @apply bg-[#0094FF] opacity-75;
            }
        }
    }
}
</style>
