import { useAuthStore } from '../../_base/store/auth';

export async function $getSession() {
    const authStore = useAuthStore();
    const config = useRuntimeConfig();

    if (process.client) {
        const identity = Math.floor(Math.random() * 255).toString();

        if (config?.public?.STORYBLOK_VERSION === 'draft') {
            const newDiv: any = document.createElement('div');
            const newContent: any = document.createTextNode('Session is loading.');
            newDiv.classList.add('session-notify');
            newDiv.classList.add('id-' + identity);
            // setTimeout(() => {
            //     newDiv.classList.add('open');
            // }, 100);
            newDiv.appendChild(newContent);
            const currentDiv = document.getElementById('__nuxt');
            document.body.insertBefore(newDiv, currentDiv);
        }

        console.log('Requesting Session');
        useFetch(`${process.dev ? '/proxy' : ''}/session`, {
            // // @ts-ignore
            onResponse({ response }) {
                if (response && response.ok) {
                    console.log('Received Session');
                    authStore.update(response?._data);
                    if (config?.public?.STORYBLOK_VERSION === 'draft') {
                        document.querySelector('.session-notify.id-' + identity).innerHTML = 'Session loaded';
                        //     document.querySelector('.session-notify.id-' + identity)?.classList.add('!bg-secondary');
                        //     setTimeout(() => {
                        //         document.querySelector('.session-notify.id-' + identity)?.classList.remove('open');
                        //         document.querySelector('.session-notify.id-' + identity)?.classList.add('close');
                        //         document.querySelector('.session-notify.id-' + identity)?.remove();
                        //     }, 5000);
                    }
                }
            },
            onResponseError({ error }) {
                if (error) {
                    console.log('Failed to get Session');
                    console.error(error);
                    if (config?.public?.STORYBLOK_VERSION === 'draft') {
                        // @ts-ignore
                        newDiv.classList.add('open');
                        document.querySelector('.session-notify').innerHTML = 'Session Failed to Load';
                        document.querySelector('.session-notify')?.classList.add('!bg-red-500');
                    }
                }
            },
        });
    }

    return;
}
