<template>
    <section class="front-section" :style="`background-color: ${blok.BackgroundColor?.color};`">
        <div class="container px-4 xl:px-0 mx-auto">
            <h2 class="front-section-title">{{ blok.Title }}</h2>
            <div class="rich-text rich-text--big" v-html="renderRichText(blok.Text)"></div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { ISeoSection } from '~/types/SeoSection';
defineProps<{ blok: ISeoSection }>();
</script>

<style scoped lang="postcss">
.rich-text--big {
    :deep(p) {
        @apply text-base md:text-xl;
        line-height: 1.4;
    }
    :deep(a) {
        @apply hover:underline;
    }
}
</style>
