<template>
    <div class="benefit-item" :class="benefit.mobile_style">
        <NuxtImg
            v-if="benefit.icon?.filename"
            :src="benefit.icon.filename"
            :title="benefit.icon.title"
            :alt="benefit.icon.alt"
            provider="storyblok"
            format="webp"
            loading="lazy"
            :class="{
                'icon-large': benefit.icon_size !== 'small',
                'icon-small': benefit.icon_size === 'small',
            }"
            densities="x1"
        />
        <div
            v-if="benefit.title"
            class="title"
            :style="`${benefit.title_font_weight ? `font-weight: ${benefit.title_font_weight};` : ''}`"
        >
            {{ benefit.title }}
        </div>
        <p v-if="benefit.text" class="content" v-html="benefit.text"></p>
    </div>
</template>

<script lang="ts" setup>
import type { ITextTitleIcon } from '~ui/types/components/TextTitleIcon';

const benefit = defineProps<ITextTitleIcon>();
</script>

<style lang="postcss" scoped></style>
