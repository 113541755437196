<template>
    <div>
        <UiModalsSignUpModal id="sbSignInModal" :blok="blok" :form-state="formState" @submit="submitForm" />
    </div>
</template>

<script lang="ts" setup>
import type {
    ISignUpModalConfig,
    ISignUpFormData,
    ISignUpFormState,
} from '@nuxt-monorepo/ui/types/components/modals/SignUpModal';

const props = defineProps<{ blok: ISignUpModalConfig }>();

const formState = ref<ISignUpFormState>({
    success: false,
    isSubmitted: false,
    responseMsg: null,
});

const config = useRuntimeConfig();
const { $encodeBase64, $decodeBase64 } = useNuxtApp();
const returnUrl = config?.public.RETURN_URL;
const submitForm = async (formData: ISignUpFormData) => {
    formState.value.responseMsg = null;
    formState.value.success = false;
    formState.value.isSubmitted = false;

    await $publicApi('register', {
        method: 'POST',
        body: formData,
        onResponse: async ({ response }) => {
            if (response.ok) {
                formState.value.success = !!response._data.data?.success;
                formState.value.isSubmitted = true;
                const redirectSplit = response._data.message.redirect.split('&return_url=');
                console.error(
                    'Redirecting To:' +
                        (redirectSplit[0] + '&return_url=' + $encodeBase64($decodeBase64(returnUrl) + '/customer'))
                );
                navigateTo(redirectSplit[0] + '&return_url=' + $encodeBase64($decodeBase64(returnUrl) + '/customer'), {
                    external: true,
                });
            }
        },
        onResponseError: async ({ response }) => {
            if (response._data?.errors?.errors?.error?.[0]) {
                formState.value.responseMsg = response._data.errors.errors.error[0];
                //@ts-ignore
            } else if (
                response._data.errors &&
                //@ts-ignore
                Object.values(response._data.errors)?.[0].isArray &&
                //@ts-ignore
                Object.values(response._data.errors)?.[0]?.[0]
            ) {
                //@ts-ignore
                formState.value.responseMsg = Object.values(response._data.errors)?.[0]?.[0];
            } else if (response._data.errors.errors) {
                //@ts-ignore
                formState.value.responseMsg = response._data.errors.errors;
            } else if (response._data.message) {
                formState.value.responseMsg = response._data.message;
            } else {
                formState.value.responseMsg = props.blok.errorMessage;
            }
            formState.value.success = false;
            formState.value.isSubmitted = true;
        },
    }).catch((err: any) => console.error('Error in first API call:', err));
};
</script>

<style lang="postcss" scoped></style>
