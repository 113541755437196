<template>
    <section class="front-section" :style="`background-color: ${blok.BackgroundColor?.color};`">
        <div class="container px-4 xl:px-0 mx-auto">
            <h2 v-if="blok.Title" class="front-section-title">
                {{ blok.Title }}
            </h2>
            <div v-if="blok.Subtitle" class="front-section-subtitle">{{ blok.Subtitle }}</div>
            <div class="flex flex-col md:flex-row gap-5 justify-center items-stretch">
                <div v-for="offer in blok.OfferCards" :key="offer._uid" class="md:w-1/3">
                    <OfferCard :card="offer" />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { IOffersSection } from '~/types/OffersSection';
import OfferCard from '~/components/cards/OfferCard.vue';

defineProps<{ blok: IOffersSection }>();
</script>

<style lang="postcss" scoped></style>
