<template>
    <section class="partners-section" :style="`background-color: ${partners?.BackgroundColor?.color};`">
        <div class="container px-4 xl:px-0 mx-auto">
            <h2 class="partners-section__title">{{ partners?.Title }}</h2>
            <LazyClientOnly v-if="partners.Images.length">
                <carousel
                    ref="partnersCarousel"
                    :items-to-show="getItemsToShow"
                    snap-align="start"
                    class="partners-carousel"
                    :class="{ 'is-centered': partners?.Images?.length <= getItemsToShow }"
                >
                    <slide v-for="image in partners.Images" :key="image.id">
                        <NuxtImg
                            v-if="image?.filename"
                            :src="image.filename"
                            :title="image.title"
                            :alt="image.alt"
                            provider="storyblok"
                            format="webp"
                            loading="lazy"
                            class="partners-carousel__image"
                            densities="x1"
                        />
                    </slide>

                    <template #addons>
                        <navigation v-if="getItemsToShow && partners?.Images?.length > getItemsToShow">
                            <template #prev>
                                <NuxtIcon name="ri:arrow-left-line" />
                            </template>
                            <template #next>
                                <NuxtIcon name="ri:arrow-right-line" />
                            </template>
                        </navigation>
                        <Pagination />
                    </template>
                </carousel>
            </LazyClientOnly>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { IPartnersSection } from '~/types/PartnersSection';
defineProps<{ partners: IPartnersSection }>();

import 'vue3-carousel/dist/carousel.css';
// @ts-ignore
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import screens from '#tailwind-config/theme/screens';

const partnersCarousel: any = ref(null);

const screenMd = Number(screens.md.slice(0, -2));
const screenLg = Number(screens.lg.slice(0, -2));
const screenXl = Number(screens.xl.slice(0, -2));
const innerWidth = ref(1000);

function onResize() {
    innerWidth.value = window.innerWidth;
}

onMounted(() => {
    window.addEventListener('resize', onResize);
    onResize();
});
onUnmounted(() => window.removeEventListener('resize', onResize));

const getItemsToShow = computed(() => {
    if (innerWidth.value >= screenXl) {
        return 8;
    } else if (innerWidth.value < screenXl && innerWidth.value >= screenLg) {
        return 6;
    } else if (innerWidth.value < screenLg && innerWidth.value >= screenMd) {
        return 4;
    } else {
        return 3;
    }
});
</script>

<style scoped lang="postcss">
.partners-carousel {
    &.is-centered {
        :deep(.carousel__track) {
            @apply justify-center;
        }
    }
    &__image {
        @apply block max-h-20 object-contain object-center px-3;
    }
}

.partners-section {
    @apply pt-6 md:pt-10 pb-10 md:pb-16;
    &__title {
        @apply text-center mb-12 text-base md:text-xl;
    }
    :deep(.carousel) {
        .carousel__next,
        .carousel__prev {
            @apply h-10 w-10 text-lg rounded-full bg-white transition-all -translate-y-1/2 text-[#0094FF] mx-0;
            @apply xl:w-12 xl:h-12 xl:text-2xl top-[40px];

            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
        }
        .carousel__next {
            @apply -right-4 sm:right-0 xl:left-full;
        }
        .carousel__prev {
            @apply -left-4 sm:left-0 xl:right-full xl:-translate-x-full;
        }
        .carousel__next--disabled,
        .carousel__prev--disabled {
            @apply opacity-0 pointer-events-none;
        }
        .carousel__pagination {
            @apply flex sm:hidden mt-6;
        }
        .carousel__pagination-button {
            &::after {
                @apply w-2 h-2 rounded-full bg-gray-400 opacity-25;
            }
        }
        .carousel__pagination-button--active,
        .carousel__pagination-button:hover {
            &::after {
                @apply bg-[#0094FF] opacity-75;
            }
        }
        .iconify {
            @apply w-6 h-6;
        }
    }
}
</style>
