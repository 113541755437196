<template>
    <SbTrustpilot id="SbTrustpilot" :blok="prop" />
</template>

<script setup>
const { blok } = defineProps({ blok: Object });
const prop = computed(() => {
    const cloneProps = { ...blok };
    cloneProps.Stars = blok.Stars || '4,5';
    cloneProps.Businessunit_id = blok.Businessunit_id || '54b961470000ff00057cc960';
    cloneProps.Style = blok.Style || (blok.Small ? '539ad60defb9600b94d7df2c' : '54ad5defc6454f065c28af8b');

    return cloneProps;
});
</script>

<style lang="postcss" scoped>
#SbTrustpilot {
    @apply flex justify-center;

    :deep(.container) {
        @apply w-full;

        h3 {
            @apply text-center mt-10 text-2xl md:text-[40px] text-gray-800 font-normal;

            &.mini {
                @apply text-lg md:text-2xl font-medium;
            }
        }

        .trustpilot {
            @apply pt-4 h-[285px];

            .trustpilot-widget {
                @apply h-full;
                iframe {
                    @apply h-full;
                }
            }

            &.small {
                @apply h-[315px];
            }
        }
    }

    &.small {
        :deep(.container) {
            h3 {
                @apply text-[2rem] font-semibold mt-0 mb-6 leading-[1.2] text-[#2C4657];
            }
        }
    }
}
</style>
