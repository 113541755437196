<template>
    <div
        class="banner"
        :class="`d-text-${blok.TextAlign} ${blok.hideOn ? `hidden-${blok.hideOn}` : ''} ${
            blok.Size === 'big' ? `banner--lg` : ``
        }`"
        :style="`background-color: ${blok.BackgroundColor.color}; color: ${blok.TextColor.color}`"
    >
        <component :is="titleTag" v-if="blok.Title" class="title" :style="titleStyles">
            {{ blok.Title }}
        </component>
        <p>{{ blok.Text }}</p>
    </div>
</template>

<script setup lang="ts">
import type { IBanner } from '~ui/types';

const { blok } = defineProps<{ blok: IBanner }>();
const titleTag = computed(() => (blok.TitleHeadingType ? blok.TitleHeadingType : 'h3'));
const titleStyles = computed(() => `${blok.TitleColor?.color ? `color: ${blok.TitleColor?.color}` : ''};`);
</script>

<style scoped lang="postcss">
.banner {
    @apply px-4 py-3 text-[13px];

    &.hidden-desktop {
        @apply md:hidden;
    }

    &.hidden-mobile {
        @apply max-md:hidden;
    }

    &.d-text {
        &-center {
            @apply text-center;
        }

        &-left {
            @apply text-left;
        }

        &-right {
            @apply text-right;
        }
    }

    h1.title {
        @apply text-5xl font-bold;
    }

    h2.title {
        @apply text-4xl font-bold;
    }

    h3.title {
        @apply text-3xl font-bold;
    }

    h4.title {
        @apply text-2xl font-bold;
    }

    h5.title {
        @apply text-xl font-bold;
    }

    h6.title {
        @apply text-lg font-bold;
    }
}
</style>
