<template>
    <section class="front-section" :style="`background-color: ${blok.BackgroundColor?.color};`">
        <div class="container px-4 xl:px-0 mx-auto">
            <h2 v-if="blok.Title" class="front-section-title">{{ blok.Title }}</h2>
            <div v-if="blok.Subtitle" class="front-section-subtitle">{{ blok.Subtitle }}</div>
            <div class="flex flex-col md:flex-row justify-center items-stretch gap-8 md:gap-5">
                <div v-for="{ content } in blok.Airports" :key="content._uid" class="md:w-1/3">
                    <AirportCard :card="content"></AirportCard>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { IImageGridAirportsV2 } from '~/types/ImageGridAirportsV2';
import AirportCard from '~/components/cards/AirportCard.vue';

defineProps<{ blok: IImageGridAirportsV2 }>();
</script>

<style scoped lang="postcss"></style>
