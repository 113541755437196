<template>
    <SbFooter :blok="blok" accordion-icon="arrow-down-s-line" />
</template>

<script setup>
defineProps({ blok: Object });
</script>

<style lang="postcss">
#sbFooter {
    @apply bg-[#f8f9fa] text-gray-800 text-sm;
    @apply border border-b-0 border-r-0 border-l-0 sm:border-t-0 border-solid border-[#adbfd1];

    .footer-container {
        @apply py-4 sm:py-0 sm:px-4;
    }

    .accordion {
        .accordion-header {
            @apply p-4 font-semibold text-[#1f303d] leading-tight;
        }
        .accordion-body {
            @apply px-4;
            a {
                @apply block w-full py-2 text-sm text-[#43667a];
            }
        }
        .accordion-icon {
            transition: all 0.35s ease;
            @apply mt-0;
            svg {
                path {
                    stroke-width: 5%;
                    stroke: #1f303d;
                }
            }
        }

        &.active {
            .accordion-icon {
                @apply -mt-1 rotate-180;
            }
        }
    }

    .links {
        @apply sm:pt-12;
    }

    .links-col-title {
        @apply font-semibold;
    }

    .links-lists-container {
        @apply justify-around flex-wrap lg:px-20;
    }

    .socials {
        @apply mx-8 mt-8 mb-2 flex justify-between items-center text-base text-primary-500;
        @apply sm:m-4 sm:mt-0 sm:justify-center;
    }
    .socials-icon-wrap {
        @apply sm:inline-flex sm:justify-center sm:items-center;
        @apply sm:w-8 sm:h-8 sm:m-3 sm:p-1 sm:rounded-full sm:bg-[#557a8d] sm:text-white;
    }
    .socials-text {
        @apply hidden;
    }

    .copyright {
        @apply p-4 sm:p-2 sm:border sm:border-b-0 sm:border-r-0 sm:border-l-0 sm:border-solid sm:border-[#ced5db];
    }
    .copyright-content {
        @apply sm:mt-2 sm:mb-5;

        .address {
            @apply hidden sm:block;
        }
    }
}
</style>
