<template>
    <UiSearchboxMain
        id="sb_searchbox"
        :blok="blok"
        :loading="loading"
        :titles="titles"
        @submit-storyblok="submitSearch"
    />
</template>

<script setup lang="ts">
import type { IHeroTitleItem, ISearchboxConfig } from '~ui/types';

const { blok, titles } = defineProps<{ blok: ISearchboxConfig; titles: IHeroTitleItem[] }>();
const config = useRuntimeConfig();
const loading = ref(false);
const submitSearch = async (body) => {
    loading.value = true;
    await $publicApi('search', {
        method: 'POST',
        body,
        onResponse({ response }) {
            if (response.ok) {
                loading.value = false;
                let redirectUrl = response._data.message.redirect;
                // Remove Absolute Link
                redirectUrl = redirectUrl.replace(config?.public.BOOKING_URL, '');

                navigateTo(redirectUrl, { external: true });
            }
        },
        onResponseError({ response }) {
            loading.value = false;
            console.error(response._data.message);
            console.error(response._data.errors);
        },
    });
};
</script>

<style lang="postcss">
#sb_searchbox {
    @apply md:bg-[#f5f9fc] max-w-6xl mx-auto;
    .icons {
        @apply flex items-center;
        .iconify {
            @apply w-4 h-4 inline-block;
            &.i-ri\:arrow-down-s-line {
                @apply w-6 h-6;
            }
        }
    }
    .form {
        .iconify {
            @apply relative -top-[2px] w-4 h-4;
            &.i-ri\:arrow-down-s-line {
                @apply relative -top-[5px] w-6 h-6;
            }
            &.loading-icon {
                @apply w-5 h-5 mt-1;
            }
        }
        .airport .dropdown-list {
            @apply xl:min-w-[500px];
        }
        .input-group-container {
            .input-group-title {
                @apply font-semibold text-xs md:text-[0.8125rem] text-gray-600;
            }
            .input-group {
                input {
                    @apply border-[#aebfd1] text-sm text-[#495057] font-light;

                    &::placeholder {
                        @apply text-gray-400;
                    }
                }

                .tooltip {
                    @apply bg-[#557A8D];
                }

                .tooltip-handle:hover + .tooltip {
                    &::before {
                        @apply bg-[#557A8D];
                    }
                }
            }

            .dates-container {
                @apply min-w-[300px];
            }

            .dropdown-list,
            .dropdown-select {
                @apply border-[#aebfd1];
                .dropdown-list-item {
                    @apply border-[#dee2e6] text-sm text-[#495057] hover:bg-primary-500 hover:text-white;

                    &.active {
                        @apply bg-[#e0ecf3] hover:bg-primary-500 hover:text-white;
                    }
                }
            }

            &.error {
                input {
                    @apply !border-error;
                }

                .error-tooltip {
                    @apply bg-error;
                    &::before {
                        @apply bg-error;
                    }
                }
            }

            @media screen(md) {
                .dropdown-select {
                    @apply text-sm;
                    .dropdown-select-item-title {
                        @apply flex items-center;
                    }
                    .dropdown-select-item {
                        @apply py-1;
                    }
                    .dropdown-select-item-selectors {
                        .button.disabled {
                            @apply text-gray-400;
                        }
                    }
                }
            }
        }
    }
}
</style>
