<template>
    <div>
        <UiAlert v-editable="blok" :blok="blok" :message="message" :name="icon" :status="status" />
    </div>
</template>

<script lang="ts" setup>
import type { Alert } from '~ui/types/components/ALert';

defineProps<Alert>();
</script>

<style lang="postcss" scoped>
:deep(.alert) {
    @apply w-full px-5 py-3 text-sm rounded;
    &.alert-success {
        @apply bg-emerald-500 text-white;
    }
    &.alert-warning {
        @apply bg-orange-100 text-orange-800;
    }
    &.alert-error {
        @apply bg-[#FFD7D7] text-[#851D1D];
    }
    .alert-text {
        @apply mr-2;
    }
}
</style>
