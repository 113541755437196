import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAirportProductsMap, LazyAirportStats, LazyAirportTabs, LazyAlert, LazyAnimatedText, LazyBanner, LazyBecomePartner, LazyBecomePartnerSection, LazyBenefits, LazyBreadcrumbs, LazyButton, LazyCardInfoList, LazyCardItem, LazyCheapAirportSection, LazyComponentList, LazyCoreAccordion, LazyCoreAccordionList, LazyCoreAdvancedTitle, LazyCoreCountdownSection, LazyCoreDynamicComponents, LazyCoreEventComponent, LazyCoreFeatureList, LazyCoreLayout, LazyCoreLinkWrapper, LazyCorePage, LazyCorePropelProductCard, LazyCoreSimpleCard, LazyCoreSimpleImage, LazyCoreSimpleLink, LazyCoreSimpleRichText, LazyCoreSimpleTitle, LazyCountdownCodeReveal, LazyCountdownEmailSubscription, LazyCountdownEmailSubscriptionForm, LazyCountdownTimer, LazyCouponsSection, LazyDividedStats, LazyEmailSubscription, LazyEmailSubscriptionComponent, LazyFaqs, LazyFooter, LazyFormDatepicker, LazyFormInput, LazyFormSelect, LazyFormTextarea, LazyGeneralText, LazyGoCertify, LazyHeader, LazyHelpCancellation, LazyHelpContact, LazyHelpNav, LazyHelpReceipt, LazyHelpSection, LazyHero, LazyHotelProductCard, LazyHotelProductCarousel, LazyHotelProductGrid, LazyHowToSaveSection, LazyImageGrid, LazyImageGridAirports, LazyImageGridAirportsV2, LazyImageText, LazyImageTmp, LazyInfoSection, LazyLinkImage, LazyLinkList, LazyLinkText, LazyMultiColumnCards, LazyMultiColumnsLayout, LazyMultiFaqsSection, LazyNarrowHero, LazyOffersSection, LazyOtNotice, LazyPSFAirportPage, LazyPackagesGrid, LazyPage, LazyParkingProductCard, LazyPartnersContainer, LazyProductGrid, LazyResetPasswordModal, LazySearchbox, LazySeoSection, LazySignInModal, LazySignUpModal, LazySimpleHero, LazySimpleImage, LazySimpleRichtext, LazySimpleText, LazySteps, LazyStickyBar, LazyTextHtml, LazyTextTitle, LazyTextTitleIconList, LazyTextTitleList, LazyTrustpilot, LazyTwoColumnLayout, LazyUsefulLinks, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["AirportProductsMap", LazyAirportProductsMap],
["AirportStats", LazyAirportStats],
["AirportTabs", LazyAirportTabs],
["Alert", LazyAlert],
["AnimatedText", LazyAnimatedText],
["Banner", LazyBanner],
["BecomePartner", LazyBecomePartner],
["BecomePartnerSection", LazyBecomePartnerSection],
["Benefits", LazyBenefits],
["Breadcrumbs", LazyBreadcrumbs],
["Button", LazyButton],
["CardInfoList", LazyCardInfoList],
["CardItem", LazyCardItem],
["CheapAirportSection", LazyCheapAirportSection],
["ComponentList", LazyComponentList],
["CoreAccordion", LazyCoreAccordion],
["CoreAccordionList", LazyCoreAccordionList],
["CoreAdvancedTitle", LazyCoreAdvancedTitle],
["CoreCountdownSection", LazyCoreCountdownSection],
["CoreDynamicComponents", LazyCoreDynamicComponents],
["CoreEventComponent", LazyCoreEventComponent],
["CoreFeatureList", LazyCoreFeatureList],
["CoreLayout", LazyCoreLayout],
["CoreLinkWrapper", LazyCoreLinkWrapper],
["CorePage", LazyCorePage],
["CorePropelProductCard", LazyCorePropelProductCard],
["CoreSimpleCard", LazyCoreSimpleCard],
["CoreSimpleImage", LazyCoreSimpleImage],
["CoreSimpleLink", LazyCoreSimpleLink],
["CoreSimpleRichText", LazyCoreSimpleRichText],
["CoreSimpleTitle", LazyCoreSimpleTitle],
["CountdownCodeReveal", LazyCountdownCodeReveal],
["CountdownEmailSubscription", LazyCountdownEmailSubscription],
["CountdownEmailSubscriptionForm", LazyCountdownEmailSubscriptionForm],
["CountdownTimer", LazyCountdownTimer],
["CouponsSection", LazyCouponsSection],
["DividedStats", LazyDividedStats],
["EmailSubscription", LazyEmailSubscription],
["EmailSubscriptionComponent", LazyEmailSubscriptionComponent],
["Faqs", LazyFaqs],
["Footer", LazyFooter],
["FormDatepicker", LazyFormDatepicker],
["FormInput", LazyFormInput],
["FormSelect", LazyFormSelect],
["FormTextarea", LazyFormTextarea],
["GeneralText", LazyGeneralText],
["GoCertify", LazyGoCertify],
["Header", LazyHeader],
["HelpCancellation", LazyHelpCancellation],
["HelpContact", LazyHelpContact],
["HelpNav", LazyHelpNav],
["HelpReceipt", LazyHelpReceipt],
["HelpSection", LazyHelpSection],
["Hero", LazyHero],
["HotelProductCard", LazyHotelProductCard],
["HotelProductCarousel", LazyHotelProductCarousel],
["HotelProductGrid", LazyHotelProductGrid],
["HowToSaveSection", LazyHowToSaveSection],
["ImageGrid", LazyImageGrid],
["ImageGridAirports", LazyImageGridAirports],
["ImageGridAirportsV2", LazyImageGridAirportsV2],
["ImageText", LazyImageText],
["ImageTmp", LazyImageTmp],
["InfoSection", LazyInfoSection],
["LinkImage", LazyLinkImage],
["LinkList", LazyLinkList],
["LinkText", LazyLinkText],
["MultiColumnCards", LazyMultiColumnCards],
["MultiColumnsLayout", LazyMultiColumnsLayout],
["MultiFaqsSection", LazyMultiFaqsSection],
["NarrowHero", LazyNarrowHero],
["OffersSection", LazyOffersSection],
["OtNotice", LazyOtNotice],
["PSFAirportPage", LazyPSFAirportPage],
["PackagesGrid", LazyPackagesGrid],
["Page", LazyPage],
["ParkingProductCard", LazyParkingProductCard],
["PartnersContainer", LazyPartnersContainer],
["ProductGrid", LazyProductGrid],
["ResetPasswordModal", LazyResetPasswordModal],
["Searchbox", LazySearchbox],
["SeoSection", LazySeoSection],
["SignInModal", LazySignInModal],
["SignUpModal", LazySignUpModal],
["SimpleHero", LazySimpleHero],
["SimpleImage", LazySimpleImage],
["SimpleRichtext", LazySimpleRichtext],
["SimpleText", LazySimpleText],
["Steps", LazySteps],
["StickyBar", LazyStickyBar],
["TextHtml", LazyTextHtml],
["TextTitle", LazyTextTitle],
["TextTitleIconList", LazyTextTitleIconList],
["TextTitleList", LazyTextTitleList],
["Trustpilot", LazyTrustpilot],
["TwoColumnLayout", LazyTwoColumnLayout],
["UsefulLinks", LazyUsefulLinks],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
