<template>
    <UiEmailSubscription id="SbEmailSubscription" :blok="props.blok" @submit="subscribeEmail" />
</template>

<script setup lang="ts">
import { useEmailSubscriptionStore } from '../../_base/store/emailSubscription';
import type { IEmailSubscriptionConfig } from '@nuxt-monorepo/ui/types';

const { t } = useI18n();
const props = defineProps<{ blok: IEmailSubscriptionConfig }>();
const emailSubscriptionStore = useEmailSubscriptionStore();
const backendError = ref('');

provide('backendError', backendError);

const subscribeEmail = async ({ email }) => {
    return await $publicApi(`newsletters/subscribe`, {
        method: 'POST',
        body: JSON.stringify({ subscribe_email: email }),
        headers: {
            'Content-Type': 'application/json',
        },
        onResponse({ response }) {
            if (response.ok) {
                emailSubscriptionStore.updateIsSubscribed(true);
                backendError.value = '';
            }
        },
        onResponseError({ response }) {
            if (response) {
                backendError.value = response.message;
            }
        },
    }).catch(() => {
        backendError.value = t('forms.emailUnSubscriptionInvalid2');
    });
};
</script>

<style lang="postcss" scoped>
#SbEmailSubscription {
    @apply w-full;
    :deep(.container) {
        .success,
        .error-tooltip {
            @apply hidden;
        }
    }
}
</style>
