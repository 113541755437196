<template>
    <section
        v-if="blok"
        v-editable="blok"
        :class="[blok.Mini ? 'mini' : '', 'sbBenefits', blok.Style, layoutClass]"
        :style="{ 'background-color': blok.BackgroundColor?.color }"
    >
        <div class="container mx-auto">
            <h2 v-if="blok.Title">{{ blok.Title }}</h2>

            <template v-if="!isCarouselView">
                <div v-show="blok.items && selectedTab" class="items">
                    <template v-for="item in blok.items">
                        <template v-if="selectedTab === item?.Type || item?.Type === 'None'">
                            <BenefitItem
                                v-for="benefit in item.items"
                                :key="benefit.icon?.filename"
                                v-bind="benefit"
                                :class="benefit.mobile_style"
                            />
                        </template>
                    </template>
                </div>
            </template>

            <LazyClientOnly v-else>
                <Carousel
                    ref="carouselRef"
                    :items-to-show="1"
                    class="benefits-carousel"
                    :autoplay="5000"
                    :wrap-around="true"
                >
                    <template v-for="item in blok.items">
                        <template v-if="selectedTab === item?.Type || item?.Type === 'None'">
                            <Slide v-for="benefit in item.items" :key="benefit.icon?.filename">
                                <BenefitItem v-bind="benefit" :class="benefit.mobile_style" />
                            </Slide>
                        </template>
                    </template>

                    <template #addons="{ slidesCount }">
                        <Pagination v-if="slidesCount > 1" />
                    </template>
                </Carousel>
            </LazyClientOnly>
        </div>
    </section>
</template>

<script setup lang="ts">
import 'vue3-carousel/dist/carousel.css';
import { useSearchStore } from '../../../../apps/_base/store/search';
import type { IBenefits } from '~ui/types/components/Benefits';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';

import BenefitItem from '~ui/components/Benefits/BenefitItem.vue';

const { isMobileView } = useIsMobileView(screens.md);
const { blok } = defineProps<{ blok: IBenefits }>();

const carouselRef: any = ref(null);

const searchStore: any = useSearchStore();
const selectedTab: any = useState('activeTab', () => getDefaultTab());

function getDefaultTab() {
    if (searchStore.activeTab) {
        return searchStore.activeTab;
    } else if (blok.items && blok.items[0]) {
        return blok.items[0]?.Type;
    } else {
        return null;
    }
}

watch(searchStore, (store) => {
    if (selectedTab.value && store.activeTab != selectedTab.value) {
        selectedTab.value = searchStore.activeTab;
    }
});

const layoutClass = computed(() => (blok.Layout === 'default' ? '' : blok.Layout));

const isCarouselView = computed(() => blok.Style === 'carousel-view-mobile' && isMobileView.value);
</script>

<style lang="postcss" scoped>
.sbBenefits {
    @apply pt-4 pb-12 md:py-12;

    .items {
        @apply flex flex-col md:flex-row items-center md:items-start justify-center text-center;
    }

    :deep(.benefit-item) {
        @apply flex flex-col items-center max-w-[290px] mx-12 text-body;

        img {
            @apply mb-4 md:my-4 mx-auto order-2 md:order-1;
        }
        .icon-large {
            @apply w-[80px] md:w-[150px] w-[80px] md:h-[150px] object-contain;
        }
        .icon-small {
            @apply w-[50px];
        }

        .title {
            @apply order-1 md:order-2;
        }

        .content {
            @apply order-3;
        }
    }

    &.mini {
        @apply pt-6 pb-6;

        :deep(.benefit-item) {
            img {
                @apply md:w-[80px] md:h-[80px];

                &.icon-small {
                    @apply md:w-[50px] md:h-[50px];
                }
            }

            .title {
                @apply mb-4 text-xl text-gray-800;
            }

            .content {
                @apply text-body;
            }
        }
    }

    h2 {
        @apply mb-12 text-center text-[1.75rem];
    }

    &.partner {
        @apply pt-[5.5rem] border-b-[rgba(0,0,0,0.1)] border-b border-solid;

        h2 {
            @apply text-[1.75rem] text-[#343A40];
        }

        :deep(.benefit-item) {
            @apply mx-0 my-0 max-w-[unset] lg:mx-[4.5rem] mb-5 lg:mb-0 last:mb-0;

            &:last-of-type {
                @apply pb-[3.3rem];
            }

            img {
                @apply !w-[150px] h-[150px];
            }

            .content {
                @apply text-sm text-[#212529];
            }
        }

        @media screen and (max-width: 768px) {
            @apply mx-16;
            h2 {
                @apply text-[1.25rem];
            }
        }
    }

    .benefits-carousel {
        .benefit-item {
            @apply pt-0 pb-2 px-8;
        }

        :deep(.carousel__pagination) {
            @apply mt-0;
        }
        :deep(.carousel__pagination-button) {
            @apply border-y-[10px] border-transparent border-solid p-0;
            &:after {
                @apply p-0 mx-2.5 w-3 h-3 bg-[#dbdcde] rounded-full;
            }
        }
        :deep(.carousel__pagination-button--active) {
            &:after {
                @apply bg-secondary;
            }
        }
    }
}
</style>
