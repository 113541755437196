<template>
    <div class="alert" :class="[`alert-${status}`]">
        <NuxtIcon v-if="icon" :name="`ri:${icon}`" class="alert-text" />
        <span class="alert-text">{{ message }}</span>
    </div>
</template>

<script setup lang="ts">
import type { Alert } from '~ui/types/components/ALert';

defineProps<Alert>();
</script>
