<template>
    <div>
        <UiModalsSignInModal
            id="sbSignInModal"
            :blok="blok"
            :form-state="formState"
            :apr="false"
            @submit="submitForm"
        />
    </div>
</template>

<script lang="ts" setup>
import type { ISignUpFormState } from '@nuxt-monorepo/ui/types/components/modals/SignUpModal';
import type { ISignInModalConfig, ISignInFormData } from '@nuxt-monorepo/ui/types/components/modals/SignInModal';

const props = defineProps<{ blok: ISignInModalConfig }>();

const formState = ref<ISignUpFormState>({
    success: false,
    isSubmitted: false,
    responseMsg: null,
});

const config = useRuntimeConfig();
const { $encodeBase64, $decodeBase64 } = useNuxtApp();
const returnUrl = config?.public.RETURN_URL;
const submitForm = async (formData: ISignInFormData) => {
    formState.value.success = false;
    formState.value.isSubmitted = false;
    formState.value.responseMsg = null;

    await $publicApi('login', {
        method: 'POST',
        body: formData,
        onResponse({ response }) {
            if (response.ok) {
                formState.value.success = true;
                formState.value.isSubmitted = true;
                const redirectSplit = response._data.message.redirect.split('&return_url=');
                console.error(
                    'Redirecting To:' +
                        (redirectSplit[0] + '&return_url=' + $encodeBase64($decodeBase64(returnUrl) + '/customer'))
                );
                navigateTo(redirectSplit[0] + '&return_url=' + $encodeBase64($decodeBase64(returnUrl) + '/customer'), {
                    external: true,
                });
            }
        },
        onResponseError({ response }) {
            if (response) {
                if (response._data?.errors?.errors?.error?.[0]) {
                    formState.value.responseMsg = response._data.errors.errors.error[0];
                    //@ts-ignore
                } else if (
                    response._data.errors &&
                    //@ts-ignore
                    Object.values(response._data.errors)?.[0].isArray &&
                    //@ts-ignore
                    Object.values(response._data.errors)?.[0]?.[0]
                ) {
                    //@ts-ignore
                    formState.value.responseMsg = Object.values(response._data.errors)?.[0]?.[0];
                } else if (response._data.errors.errors) {
                    //@ts-ignore
                    formState.value.responseMsg = response._data.errors.errors;
                } else if (response._data.message) {
                    formState.value.responseMsg = response._data.message;
                } else {
                    formState.value.responseMsg = props.blok.errorMessage;
                }
                formState.value.success = false;
                formState.value.isSubmitted = true;
            }
        },
    }).catch((err: any) => console.error(err));
};
</script>

<style lang="postcss" scoped>
:deep(form) {
    > div {
        &:first-of-type {
            .form-input {
                @apply mt-0;
            }
        }

        &:nth-of-type(2) {
            .form-input {
                @apply mb-3;
            }
        }
    }
}
</style>
