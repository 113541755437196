<template>
    <div>
        <UiModalsResetPasswordModal
            id="sbResetPasswordModal"
            :blok="blok"
            :form-state="formState"
            @submit="submitForm"
        />
    </div>
</template>

<script lang="ts" setup>
import type { ISignUpFormState } from '@nuxt-monorepo/ui/types/components/modals/SignUpModal';
import type {
    IResetPasswordFormData,
    IResetPasswordModalConfig,
} from '@nuxt-monorepo/ui/types/components/modals/ResetPasswordModal';

const props = defineProps<{ blok: IResetPasswordModalConfig }>();

const formState = ref<ISignUpFormState>({
    success: false,
    isSubmitted: false,
    responseMsg: null,
});

const submitForm = async (formData: IResetPasswordFormData) => {
    formState.value.success = false;
    formState.value.isSubmitted = false;
    formState.value.responseMsg = null;

    await $publicApi(`forgot_password`, {
        method: 'POST',
        body: formData,
        onResponse({ response }: any) {
            if (response && response.ok) {
                formState.value.success = true;
                formState.value.isSubmitted = true;
            }
        },
        onResponseError({ response }: any) {
            if (response) {
                if (response._data?.errors?.errors?.error?.[0]) {
                    formState.value.responseMsg = response._data.errors.errors.error[0];
                } else if (
                    response._data.errors &&
                    //@ts-ignore
                    Object.values(response._data.errors)?.[0].isArray &&
                    //@ts-ignore
                    Object.values(response._data.errors)?.[0]?.[0]
                ) {
                    //@ts-ignore
                    formState.value.responseMsg = Object.values(response._data.errors)[0][0];
                } else if (response._data.errors.errors) {
                    //@ts-ignore
                    formState.value.responseMsg = response._data.errors.errors;
                } else if (response._data.message) {
                    formState.value.responseMsg = response._data.message;
                } else {
                    formState.value.responseMsg = props.blok.errorMessage;
                }
                formState.value.success = false;
                formState.value.isSubmitted = true;
            }
        },
    }).catch((err: any) => console.error(err));
};
</script>

<style lang="postcss" scoped>
:deep(.user-modal) .sign-in-button.close {
    @apply bg-[rgb(240,240,240)] text-[black] border-[none];

    &:hover {
        @apply text-white bg-[#587a8c] border-[#587a8c];
    }

    &:focus {
        @apply shadow-[0_0_0_0.2rem_rgba(88,122,140,0.5)];
    }
}
</style>
