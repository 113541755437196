<template>
    <PartnersSection v-if="partners" :partners="partners" />
</template>

<script setup lang="ts">
import PartnersSection from '~/components/sections/PartnersSection.vue';
import type { IPartnersContainer, IPartnersSection } from '~/types/PartnersSection';

const { blok } = defineProps<{ blok: IPartnersContainer }>();
const uuid = blok.Partners;

const storyblokApiInstance = useStoryblokApi();
const config = useRuntimeConfig();
const { locale } = useI18n();

const { data } = await useAsyncData(
    `partners-section-${new Date().getTime()}`,
    async () =>
        await storyblokApiInstance.getStory(uuid, {
            version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
            language: locale.value,
            find_by: 'uuid',
            resolve_links: '1',
        }),
    {
        lazy: true,
    }
);
const partners = computed(() => data?.value?.data?.story?.content as IPartnersSection);
</script>

<style scoped></style>
