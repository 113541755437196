<template>
    <UiHeader id="uiHeader" :blok="blok" @logout="logout" />
</template>

<script lang="ts" setup>
import { useAuthStore } from '../../_base/store/auth';
const authStore = useAuthStore();
const route = useRoute();

defineProps<{ blok: any }>();

async function logout() {
    await $publicApi('logout', {
        method: 'POST',
        body: { _token: authStore.csrf },
        onResponse({ response }) {
            if (response.ok) {
                navigateTo(route.path, { external: true });
            }
        },
        onResponseError({ response }) {
            if (response) {
                console.error(response);
            }
        },
    });
}
</script>

<style lang="postcss">
#uiHeader {
    @apply bg-white text-black shadow-md;

    .container {
        @apply min-h-[44px] px-[6px] text-[14px] max-w-6xl;
    }

    .logo {
        @apply px-0;
    }

    .nav {
        > li {
            @apply py-[12px];
        }
        > .nav-row {
            > .nav-row-item {
                @apply py-3;
            }
        }

        .iconify {
            @apply w-6 h-6;
        }

        .nav-row {
            @apply gap-x-[30px] flex-wrap;

            .nav-row-item {
                @apply gap-x-[7px] cursor-pointer;

                &.rich-text-menu-item {
                    @apply p-0;
                    .simple-richtext {
                        span {
                            @apply text-center w-full block text-xs leading-[15px];

                            &.text {
                                @apply text-xs;
                            }

                            &.title {
                                @apply text-base;
                            }
                        }
                    }
                }

                &.dropdown {
                    .iconify {
                        @apply w-5 h-5 text-gray-500;
                    }
                }

                &.active {
                    > a {
                        @apply bg-white;
                    }

                    > span:not('.iconify') {
                        @apply bg-white underline;
                    }
                }
            }

            span {
                @apply px-0;
            }
        }

        .nav-dropdown {
            @apply text-black bg-white pb-[0.5rem] pt-0 top-full;

            &:before {
                @apply content-[''] absolute w-full h-2.5 bg-white left-0 -top-2.5;
            }

            &.right {
                @apply left-auto right-0;
            }

            &.left {
                @apply left-[-25px] right-auto;
            }

            .nav-dropdown-item,
            .nav-row-item {
                a:hover {
                    @apply bg-[#EDF0F3];
                }

                a {
                    @apply flex w-full relative items-center justify-between leading-8;
                }
            }

            &.inner {
                @apply top-[0] left-full items-baseline cursor-auto bg-[#EDF0F3] min-w-full min-h-full block pt-0 border-t-0 grid-flow-col;

                a:hover {
                    @apply bg-[#DADFE4];
                }
            }
        }
    }

    @media (max-width: 1101px) {
        .container.is-logged-in {
            @apply md:pt-4 pb-0 px-4;
            .nav {
                > .nav-row {
                    > .nav-row-item {
                        @apply py-4;
                        &:first-of-type {
                            > .nav-dropdown {
                                &.left,
                                &.center,
                                &.right {
                                    @apply left-[-16px] -translate-x-0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1052px) {
        .container {
            @apply pt-4 pb-0 px-4;
        }

        .nav {
            > .nav-row {
                > .nav-row-item {
                    @apply py-4;
                    &:first-of-type {
                        > .nav-dropdown {
                            &.left,
                            &.center,
                            &.right {
                                @apply left-[-16px] -translate-x-0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        position: relative;
        .container {
            @apply justify-center md:h-[46px] p-0;
        }

        .logo {
            @apply h-[30px];
        }

        .navigation-button {
            @apply h-full;
        }
    }
}
</style>
