<template>
    <div class="o-card" :class="card.Layout">
        <NuxtLink :to="getInternalLink(card.Link)" class="o-card__image">
            <NuxtImg
                v-if="card.Image?.filename"
                :src="card.Image.filename"
                :title="card.Image.title"
                :alt="card.Image.alt"
                provider="storyblok"
                format="webp"
                loading="lazy"
                densities="x1"
            />
        </NuxtLink>
        <div class="o-card__content">
            <NuxtLink :to="getInternalLink(card.Link)" class="o-card__title">
                <span>{{ card.Title }}</span>
                <NuxtIcon v-if="card.Layout === 'transparent'" name="ri:arrow-right-s-line" class="block" />
            </NuxtLink>
            <div class="o-card__text">{{ card.Text }}</div>
            <div v-if="card.Layout === 'shadow'" class="o-card__deal-btn">View deal</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IOfferCard } from '~/types/OfferCard';
defineProps<{ card: IOfferCard }>();
</script>

<style scoped lang="postcss">
.o-card {
    @apply relative flex flex-col h-full;
    &.shadow {
        @apply rounded;
        transition: box-shadow 0.3s;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.16);
        &:hover {
            box-shadow: 0 3px 6px 0 rgba(41, 63, 77, 0.6);
        }
    }
    &__image {
        @apply block overflow-hidden;
        &:hover {
            img {
                .transparent & {
                    transform: scale(1.04);
                }
            }
        }
        img {
            @apply block w-full object-cover h-[200px];
            .shadow & {
                @apply h-44 sm:h-[200px] rounded-t;
            }
            .transparent & {
                @apply h-[300px] rounded transition-transform;
            }
        }
    }
    &__content {
        @apply pb-3 flex flex-col grow rounded-b overflow-hidden;
        .shadow & {
            @apply bg-white pt-5 px-3;
        }
        .transparent & {
            @apply pt-3 md:pt-6;
        }
    }
    &__title {
        @apply flex items-center font-bold text-xl text-gray-800 mb-2;
        .shadow & {
            &:after {
                content: '';
                @apply absolute left-0 top-0 w-full h-full;
            }
        }
        .transparent & {
            @apply text-[#0094ff] text-base md:text-xl;
            &:hover {
                span {
                    @apply underline;
                }
            }
        }
        .iconify {
            @apply h-4 w-4 ml-3 text-sm;
        }
    }
    &__text {
        @apply text-base mb-2 md:mb-5 leading-6;
    }
    &__deal-btn {
        @apply text-tertiary-350 text-center mt-auto py-2 text-lg font-bold;
    }
}
</style>
