<template>
    <UiBenefits ref="sbBenefits" v-editable="blok" class="sb-benefits" :blok="blok" />
</template>

<script setup lang="ts">
import type { IBenefits } from '~ui/types/components/Benefits';

defineProps<{ blok: IBenefits }>();
</script>
<style lang="postcss" scoped>
.sb-benefits {
    :deep(.items) {
        .benefit-item {
            .title {
                @apply mt-12 mb-4 md:mt-0 md:mb-5 text-secondary text-2xl font-semibold;
            }

            @media (max-width: 768px) {
                &.mobile-left-align {
                    @apply gap-x-4 grid grid-cols-[80px_1fr] grid-rows-[auto_auto] max-w-none gap-y-0 text-left w-full m-0 p-4;

                    img {
                        @apply col-[1] row-[span_2] mb-0;
                    }

                    .title {
                        @apply col-[2] row-[1/2] mt-0 mb-0;
                    }

                    .content {
                        @apply col-[2] row-[2] mt-0;
                    }
                }
            }
        }
    }
    &.row {
        @apply hidden md:block;
        :deep(.items) {
            @apply grid grid-cols-3 gap-6 px-4 xl:px-0;
            .benefit-item {
                @apply grid mx-0 text-body grow max-w-none gap-x-5 text-left items-start;
                grid-template-columns: auto 1fr;
                img {
                    @apply col-span-1 row-span-2 md:w-[68px] md:h-[68px] m-0;
                }
                .title {
                    @apply col-span-1 mb-2 text-base text-gray-800 font-bold;
                }
                .content {
                    @apply col-span-1 text-sm;
                }
            }
        }
    }
}
</style>
